//forms
// import 'bootstrap-slider';
// import 'ion-rangeslider';
// import 'bootstrap-datepicker';
import 'bootstrap-daterangepicker';
// import 'bootstrap-colorpicker';
// import 'bootstrap-timepicker';
import 'inputmask';
//editors
import 'tinymce';
import '../../../node_modules/tinymce/themes/silver/theme.js';
import '../../../node_modules/tinymce/themes/mobile/theme.js';
import Inputmask from "inputmask";